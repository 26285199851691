import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import Service from '../components/Pages/Service/Service';
import Partnerships from '../components/Pages/Portfolio/Partnerships';
import Privacy from '../components/Pages/FAQ/privacy';

const AppNavigation = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // Stops observing once the element is visible
        }
      });
    });

    // Select all elements you want to animate
    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach(element => observer.observe(element));

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, []); // Empty dependency array means this runs only once when the component mounts

  return (
    <ContextProvider>
      <AnimateMouse/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <BackToTop/>
    </ContextProvider>
  );
};

export default AppNavigation;