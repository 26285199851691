import React from 'react';
import AppNavigation from "./app-navigation/AppNavigation";
import CookiePopup from './app-navigation/CookiePopup';

function App() {
  return (
    <div className="App">
      <AppNavigation />
      <CookiePopup />
    </div>
  );
}

export default App;