import React from "react";
import { Link } from "react-router-dom";
import MobileMenus from "./MobileMenus";

const OffCanvas = ({ setOffCanvas, isOffCanvas }) => {
  return (
    <>
      <div
        className={`offcanvas__area off-canvas-bg ${
          isOffCanvas ? "opened" : ""
        }`}
        style={{ backgroundImage: `url("assets/img//bg/Sidearea-bg-img.png")` }}
      >
        <div className="offcanvas_area-logo">
          <span className="offcanvas__close-btn">
            <button onClick={() => setOffCanvas(false)}>
              <i className="fal fa-times" />
            </button>
          </span>
        </div>
        <div className="offcanvas-content">
          <div className="offcanvas-subtitle">
            <span>EXPERT FINANCIAL ASSISTANCE FROM</span>
          </div>
          <div className="offcanvas-logo pb-20 pt-20">
            <img src="assets/img/logo/logowhite.png" alt="" style={{ width: '200px', height: 'auto' }}/>
          </div>
          <div className="offcanvas-details">
            <p>
            Contact us today to see how we can assist with your debt advisory needs.

            </p>
          </div>
          <div className="offcanvas-btn">
            <Link
              to="/contact"
              className="tp-slider-btn mt-40 d-inline-block"
              tabIndex="-1"
            >
              <span>
                <svg
                  width="53"
                  height="8"
                  viewBox="0 0 53 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <svg
                  width="53"
                  height="8"
                  viewBox="0 0 53 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
              Lets Talk!
            </Link>
          </div>
        </div>

        <div className="tp-mobile-menu mean-container d-block d-xl-none">
          <div className="mean-bar">
            <MobileMenus />
          </div>
        </div>

        <div className="tp-footer__widget pb-30 offcanvas-ct-info">
          <h3 className="tp-footer__widget-title">Quick Links</h3>
          <ul>
            <li>
              Scorpion Capital,<br/> 9 Portland Street <br/>Floor 2<br/>Manchester,<br/> M1 3BE
            </li>
            <li>
              <Link to="mailto:letstalk@scorpioncapital.co.uk">letstalk@scorpioncapital.co.uk</Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => setOffCanvas(false)}
        className={`body-overlay ${isOffCanvas ? "opened" : ""}`}
      ></div>
    </>
  );
};

export default OffCanvas;
