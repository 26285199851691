import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cookie.css';

const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted the cookies
    const acceptedCookies = localStorage.getItem('acceptedCookies');
    if (!acceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-popup">
      <p>
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies. 
        Please review the Scorpion Capital <Link to="/privacy">Privacy Policy</Link>.
      </p>
      <button onClick={acceptCookies}>Accept</button>
    </div>
  );
};

export default CookiePopup;